import { cn } from '@utils';

type Props = {
  className?: string;
};

export const OnueLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[289px] w-[500px] text-white', className)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 289"
      fill="currentColor"
    >
      <defs>
        <clipPath id="c">
          <path d="M0 0h499v289H0z" />
        </clipPath>
        <filter
          id="a"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
        </filter>
        <image
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfMAAAEhCAIAAADkm944AAAABmJLR0QA/wD/AP+gvaeTAAAEFElEQVR4nO3UwQnAIADAwFr331jQJQQh3E2QV8Ze8wMg5H8dAMBlzg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlDj7AA1zg5Q4+wANc4OUOPsADXODlBzAOPrBEAqrWjJAAAAAElFTkSuQmCC"
          id="b"
          width={499}
          height={289}
        />
        <mask id="e">
          <g filter="url(#a)">
            <use xlinkHref="#b" />
          </g>
        </mask>
        <g id="d" clipPath="url(#c)">
          <path
            d="M77.918 249.227V39.734c0-20.066-8.824-27.691-22.86-27.691-14.038 0-22.863 7.625-22.863 27.691v209.493c0 20.066 8.825 27.695 22.864 27.695 14.035 0 22.859-7.629 22.859-27.695ZM.105 218.328V70.637C.105 25.687 11.738 0 55.06 0c43.316 0 54.949 25.688 54.949 70.637v147.691c0 44.95-11.633 70.637-54.95 70.637-43.32 0-54.953-25.688-54.953-70.637ZM129.762 288.965V70.637C129.762 25.684 141.395 0 184.715 0c43.316 0 54.95 25.684 54.95 70.637v218.328h-32.09V39.735c0-20.067-8.825-27.692-22.86-27.692-14.04 0-22.863 7.625-22.863 27.691v249.23ZM369.32 0v218.328c0 44.95-11.632 70.637-54.949 70.637-43.32 0-54.953-25.688-54.953-70.637V0h32.09v249.227c0 20.066 8.824 27.691 22.863 27.691 14.035 0 22.86-7.625 22.86-27.691V0ZM447.129 144.469l.004-9.098 19.758-6.8V39.733c0-20.066-8.829-27.691-22.864-27.691-14.039 0-22.863 7.625-22.863 27.691v209.493c0 20.066 8.824 27.695 22.863 27.695 14.036 0 22.864-7.629 22.864-27.695v-31.024h32.086v.125c0 44.95-11.633 70.637-54.95 70.637-43.32 0-54.953-25.688-54.953-70.637V70.637C389.074 25.684 400.707 0 444.027 0c41.828 0 54.114 23.95 54.907 66.07.03 1.5.043 75.688.043 77.23v1.18h-44.864Zm0 0"
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: '#7f3f97',
              fillOpacity: 1,
            }}
          />
        </g>
      </defs>
      <use xlinkHref="#d" mask="url(#e)" />
    </svg>
  );
};
