import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import YAML from 'yamljs';
import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

import { Any, DedicatedAppName, Job, OutletContext } from '@typings';
import { axios } from '@features';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, flattenObject, path } from '@utils';

import { ChaseSpinner } from '@components';
import { JobDetailsContent, JobDetailsItem } from '@components/Job';
import { EmptyContent } from '@components/Ui';

type Props = {
  appType: DedicatedAppName | undefined;
  appName: string | null;
};

export const JobOutputsPage: React.FC<Props> = ({ appName, appType }) => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  const { setTabTitle } = useOutletContext<OutletContext.Job>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [outputs, setOutputs] = useState<Record<Any, Any>>({});

  const { storageUrl } = as.c(cluster);

  useEffect(() => {
    setTabTitle(Job.Tab.Outputs);
  }, [setTabTitle]);

  useEffect(() => {
    const run = async () => {
      try {
        const yaml = await axios.get(
          path.create(
            storageUrl,
            organizationName,
            projectName,
            '.apps',
            appType,
            appName!.replace(/-install$|-uninstall$/, ''),
            'outputs.yaml',
          ),
        );

        const outputs = YAML.parse(yaml as Any as string);

        setOutputs(flattenObject(outputs));
      } catch (error) {
        setError('No outputs found for this app or error occurred');
      } finally {
        setLoading(false);
      }
    };

    if (appName && appType) {
      run();
    } else {
      setError('This app has no outputs');
    }
  }, [storageUrl, organizationName, projectName, appName, appType]);

  const makeOutput = ([key, value]: [string, Any], index: number) => (
    <JobDetailsItem key={index} copy title={key} text={`${value}`} />
  );

  if (error) {
    return (
      <JobDetailsContent className="py-20">
        <EmptyContent
          icon={faCircleXmark}
          title="Outputs Missing"
          text={error}
        />
      </JobDetailsContent>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center py-40">
        <ChaseSpinner color="black" className="h-12 w-12" />
      </div>
    );
  }

  return (
    <JobDetailsContent>
      {Object.entries(outputs).map(makeOutput)}
    </JobDetailsContent>
  );
};
