import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { captureMessage } from '@sentry/react';

import { PATH } from '@constants';
import { conditionalProperty, invariant } from '@utils';

import { Button, Helmet } from '@components';

export const InviteSignupPage = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const email = searchParams.get('login_hint');

  useEffect(() => {
    /**
     * Exclude Sentry reports for direct access to this page
     * when both `token` and `email` are missing
     */
    if (!token && email) {
      captureMessage(
        `Token is missing for invitation signup for user ${email}.`,
      );
    }

    if (!token) {
      navigate(PATH.AUTH, { replace: true });
    }
  }, [token, email, navigate]);

  const handleRegister = () => {
    invariant(token);

    loginWithRedirect({
      authorizationParams: {
        ...conditionalProperty('login_hint', email),
        token,
        screen_hint: 'signup',
      },
    });
  };

  return (
    <>
      <Helmet
        title="Invitation Signup"
        description="Access your account securely and effortlessly by authenticating your identity"
      />
      <Button className="px-16 capitalize" onClick={handleRegister}>
        Sign up
      </Button>
      <p className="-mt-1 text-center text-footnote text-neural-04">
        Please continue with the signup flow <br /> to complete the organization
        invitation process
      </p>
    </>
  );
};
