import { Hostname } from '@typings';
import { ORIGIN } from '@constants';
import { useHostname } from '@hooks/useHostname';
import { origin } from '@content';

import {
  GeicoLogo,
  ImhpcLogo,
  Logo,
  MegaportLogo,
  NovoserveLogo,
  OnueLogo,
  ScottdcLogo,
  WwtLogo,
} from '@components/Ui';

type Props = {
  className?: string;
};

export const ThemeLogo = ({ className }: Props) => {
  const { title, checkHostname } = useHostname();

  const isBrandingLogo = title !== 'Apolo';

  const getLogoComponent = () => {
    const logo = {
      [Hostname.Scottdc]: ScottdcLogo,
      [Hostname.Novoserve]: NovoserveLogo,
      [Hostname.Geico]: GeicoLogo,
      [Hostname.Onue]: OnueLogo,
      [Hostname.Imhpc]: ImhpcLogo,
      [Hostname.Megaport]: MegaportLogo,
      [Hostname.Wwt]: WwtLogo,
    };

    if (origin[ORIGIN as Hostname]) {
      return logo[ORIGIN as Hostname];
    }

    if (checkHostname(Hostname.Scottdc)) {
      return logo[Hostname.Scottdc];
    }

    if (checkHostname(Hostname.Novoserve)) {
      return logo[Hostname.Novoserve];
    }

    if (checkHostname(Hostname.Geico)) {
      return logo[Hostname.Geico];
    }

    if (checkHostname(Hostname.Onue)) {
      return logo[Hostname.Onue];
    }

    if (checkHostname(Hostname.Imhpc)) {
      return logo[Hostname.Imhpc];
    }

    if (checkHostname(Hostname.Megaport)) {
      return logo[Hostname.Megaport];
    }

    if (checkHostname(Hostname.Wwt)) {
      return logo[Hostname.Wwt];
    }

    return Logo;
  };

  const LogoComponent = getLogoComponent();

  if (isBrandingLogo) {
    return (
      <div className="group flex flex-col items-start">
        <LogoComponent className={className} />
        <div className="group-hover::grayscale-0 flex items-center gap-1 text-body-small text-neural-03">
          <p className="whitespace-nowrap">Powered by</p>
          <Logo className="h-4 w-auto flex-initial text-neural-03 grayscale transition-all group-hover:text-primary group-hover:grayscale-0" />
        </div>
      </div>
    );
  }

  return <LogoComponent className={className} />;
};
