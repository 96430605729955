import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { App } from '@typings';
import { PATH } from '@constants';
import { getStorageApps } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

import * as Page from '@pages';

/**
 * todo: split fetching into `useApps` hook
 */
export const AppsRoutes = () => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  /**
   * Installed dedicated app names
   */
  const [installedDedicatedApps, setInstalledDedicatedApps] = useState<
    App.DedicatedModel[]
  >([]);
  const [title, setTitle] = useState('');

  const { storageUrl } = as.c(cluster);

  const getDedicatedApps = useCallback(async () => {
    if (!projectName) {
      return;
    }

    try {
      const dedicatedApps = await getStorageApps({
        organizationName,
        projectName,
        storageUrl,
      });

      setInstalledDedicatedApps(dedicatedApps);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      /**
       * Continue regardless error
       */
    }
  }, [organizationName, projectName, storageUrl]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 15_000;

    getDedicatedApps();

    const intervalId = setInterval(() => {
      getDedicatedApps();
    }, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [getDedicatedApps]);

  return (
    <Routes>
      <Route element={<Page.Apps title={title} />}>
        <Route
          index
          element={
            <Page.AppsOutlet
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="/"
          element={
            <Page.AppsOutlet
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="installed/:appName?"
          element={
            <Page.AppsInstalled
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route path="*" element={<Navigate replace to={PATH.APPS} />} />
      </Route>
    </Routes>
  );
};
