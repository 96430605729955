import { App, DedicatedAppName, Job } from '@typings';
import { DEDICATED_APP_NAME, PATH } from '@constants';
import { ensureArray } from '@utils';

import * as jobImage from '@images/jobs';

import { sidebarItems } from './shared.content';

const builtIntAppsOptions = {
  jobs: {
    description:
      'A tool enabling the execution of containerized workloads, seamlessly integrated with other apps and cluster resources',
  },
  images: {
    description:
      'A UI for the container image registry available within the cluster',
  },
  files: {
    description:
      'A file manager facilitating access to the underlying network storage within the cluster',
  },
  disks: {
    description:
      'An app for managing block storage, streamlining the configuration, allocation, and maintenance of persistent disks',
  },
  secrets: {
    description:
      'A secure secret store integrated with other apps, ensuring safe management and usage of credentials and keys',
  },
  buckets: {
    description:
      'An object storage service that enables the creation of buckets, credential acquisition, and efficient management of stored objects',
  },
  flows: {
    description:
      'A workflow engine designed to execute jobs organized in Directed Acyclic Graphs (DAGs) with integrated data caching for enhanced efficiency',
  },
};

export const builtItApps: App.BuiltInItem[] = sidebarItems
  .filter(({ path }) => ensureArray(path)[0] !== PATH.APPS)
  .map(({ solidIcon, name, path }) => {
    const formattedName = name.toLowerCase();
    /**
     * todo: resovle type warning
     */
    // @ts-ignore
    const options = builtIntAppsOptions[
      formattedName
    ] as Partial<App.BuiltInItem>;

    return {
      ...options,
      icon: solidIcon,
      name,
      path: ensureArray(path)[0],
    };
  });

export const dedicatedAppLogo: Partial<Record<DedicatedAppName, string>> = {
  [DEDICATED_APP_NAME.APOLO_DEPLOY]: jobImage.inJobDeployment,
  [DEDICATED_APP_NAME.DIFY]: jobImage.dify,
  [DEDICATED_APP_NAME.FOCUS]: jobImage.fooocus,
  [DEDICATED_APP_NAME.JUPYTER_LAB]: jobImage.jupyter,
  [DEDICATED_APP_NAME.JUPYTER]: jobImage.jupyter,
  [DEDICATED_APP_NAME.LLM_INFERENCE]: jobImage.llmInference,
  [DEDICATED_APP_NAME.MLFLOW]: jobImage.mlflow,
  [DEDICATED_APP_NAME.POSTGRESQL]: jobImage.postgresql,
  [DEDICATED_APP_NAME.PRIVATE_GPT]: jobImage.privateGpt,
  [DEDICATED_APP_NAME.PYCHARM]: jobImage.pycharm,
  [DEDICATED_APP_NAME.STABLE_DIFFUSION]: jobImage.stableDiffusion,
  [DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE]:
    jobImage.textEmbeddingsInference,
  [DEDICATED_APP_NAME.VS_CODE]: jobImage.vscode,
  [DEDICATED_APP_NAME.WEAVIATE]: jobImage.weaviate,
};

export const dedicatedApps: Job.Item[] = [
  {
    title: 'Terminal',
    name: DEDICATED_APP_NAME.SHELL,
    description:
      'A web-based remote shell instrumented with Apolo CLI, providing immediate access to dedicated compute resources',
    image: 'ghcr.io/neuro-inc/app-deployment',
    jobResourcePreset: 'cpu-small',
  },
  {
    title: 'LLM Inference',
    name: DEDICATED_APP_NAME.LLM_INFERENCE,
    description:
      'A highly-available LLM inference service with an OpenAI-compatible API, capable of efficiently serving both standard and quantized models available on HuggingFace',
    image: 'ghcr.io/neuro-inc/app-deployment',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'PostgreSQL',
    name: DEDICATED_APP_NAME.POSTGRESQL,
    description:
      'An industry-standard relational database system that includes pgvector for advanced semantic search capabilities',
    image: 'ghcr.io/neuro-inc/app-deployment',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Text Embeddings Inference',
    name: DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE,
    description:
      'Comprehensive toolkit designed for efficient deployment and serving of open source text embeddings models. It enables high-performance extraction for the most popular models',
    image: 'ghcr.io/neuro-inc/app-deployment',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'PrivateGPT',
    name: DEDICATED_APP_NAME.PRIVATE_GPT,
    description:
      'A production-ready AI project that allows you to ask questions about your documents using the power of Large Language Models (LLMs). 100% private, no data leaves your execution environment at any point',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Dify',
    name: DEDICATED_APP_NAME.DIFY,
    description:
      'Open-source LLM app development platform. Orchestrate LLM apps from agents to complex AI workflows, with an RAG engine',
    image: 'ghcr.io/neuro-inc/app-deployment',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Stable Diffusion',
    name: DEDICATED_APP_NAME.STABLE_DIFFUSION,
    description:
      'An open-source platform for generating and integrating advanced image generation into your projects. Build and orchestrate creative applications from interactive interfaces to powerful API-driven solutions, all powered by cutting-edge diffusion models',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Fooocus',
    name: DEDICATED_APP_NAME.FOCUS,
    description:
      'Free, offline, open-source image generator that creates images from prompts without manual tweaking',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Weaviate',
    name: DEDICATED_APP_NAME.WEAVIATE,
    description:
      'Robust, open-source vector database that allows you to store and query data based on its meaning. It supports various modules for text, image, and multimodal vectorization, enabling semantic search, advanced filtering, and question-answering',
    image: 'ghcr.io/neuro-inc/app-deployment',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Jupyter Notebook',
    name: DEDICATED_APP_NAME.JUPYTER,
    description:
      'An interactive tool that enables the creation and sharing of documents with live code, visualizations, and narrative text',
    image: 'ghcr.io/neuro-inc/app-deployment',
    jobResourcePreset: 'gpu-small',
  },
  {
    title: 'Jupyter Lab',
    name: DEDICATED_APP_NAME.JUPYTER_LAB,
    description:
      'An interactive development environment designed for managing notebooks, code, and data, enabling seamless creation and sharing of dynamic documents',
    image: 'ghcr.io/neuro-inc/app-deployment',
    jobResourcePreset: 'gpu-small',
  },
  {
    title: 'VS Code',
    name: DEDICATED_APP_NAME.VS_CODE,
    image: 'ghcr.io/neuro-inc/app-deployment',
    description:
      'A lightweight, powerful source code editor with a rich ecosystem of extensions for many languages and runtimes',
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'PyCharm Community Edition',
    name: DEDICATED_APP_NAME.PYCHARM,
    image: 'ghcr.io/neuro-inc/app-deployment',
    description: 'A Python IDE for data science and web development',
    jobResourcePreset: 'cpu-small',
  },
  {
    title: 'ML Flow',
    name: DEDICATED_APP_NAME.MLFLOW,
    description:
      'A tool that streamlines the full lifecycle of machine learning projects, enhancing manageability, traceability, and reproducibility',
    image: 'ghcr.io/neuro-inc/app-deployment',
    tags: ['application', 'application:mlflow'],
    jobResourcePreset: 'cpu-small',
  },
  {
    title: 'Apolo Deploy',
    name: DEDICATED_APP_NAME.APOLO_DEPLOY,
    image: 'ghcr.io/neuro-inc/app-deployment',
    description:
      'A simple model deployment service leveraging Triton and MLflow as its core inference servers',
    jobResourcePreset: 'cpu-small',
    mlflowIntegration: true,
  },
];
