import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faRectangleTerminal } from '@fortawesome/pro-solid-svg-icons';

import { App, DedicatedAppName } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { toast } from '@features';
import { createJob as uninstallDedicatedApp } from '@services';
import { contextNamesSelector, viewTypeSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import { getInstallingAppImage, path, toastifyResponseError } from '@utils';
import { builtItApps, dedicatedAppLogo } from '@content';

import { Button, Helmet, Icon, Link, Render, Table } from '@components';
import { AppItem, Job } from '@components/Ui';
import { AppInstalledDedicatedItem } from '@components/Ui/Apps';

type UninstallAppPayload = {
  /**
   * Used for setting killing app ID
   */
  appId: string;
  entrypoint?: string;
  command?: string;
};

type Props = {
  installedDedicatedApps: App.DedicatedModel[];
  setTitle: (title: string) => void;
};

export const AppsInstalledOutlet = ({
  installedDedicatedApps,
  setTitle,
}: Props) => {
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);
  const { isGridView } = useSelector(viewTypeSelector);

  const { makeTitle } = useHelmetTitle();
  const { appName } = useParams();
  const { powerlessPresetName } = usePowerlessResourcePresetName();

  const [killingAppId, setKillingAppId] = useState('');

  useEffect(() => {
    setTitle('Installed Apps');
  }, [setTitle]);

  const handleDedicatedAppUninstall = async ({
    appId,
    entrypoint,
    command,
  }: UninstallAppPayload) => {
    try {
      setKillingAppId(appId);

      await uninstallDedicatedApp({
        passConfig: true,
        clusterName: clusterName!,
        organizationName,
        projectName: projectName!,
        presetName: powerlessPresetName,
        entrypoint,
        command,
        name: `${appId}-uninstall`,
        image: getInstallingAppImage('ghcr.io/neuro-inc/app-deployment'),
      });

      toast.success('The app is being uninstalled');
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setKillingAppId('');
    }
  };

  const filterdedicatedApp = ({ appType }: App.DedicatedModel) => {
    if (!appName) {
      return true;
    }

    return appType === appName;
  };

  const isDedicatedAppHasCommand = (name: string) =>
    Object.values(DEDICATED_APP_NAME)
      .filter((name) => name !== DEDICATED_APP_NAME.POSTGRESQL)
      /**
       * todo: fix type
       */
      // @ts-ignore
      .includes(name as DedicatedAppName);

  const makeDedicatedApp = ({
    name,
    status,
    appType,
    appId,
    appUrl,
  }: App.DedicatedModel) => {
    const isUninstalling = killingAppId === name;
    const payload: UninstallAppPayload = {
      appId: name,
      command: isDedicatedAppHasCommand(appType)
        ? `delete ${appType} ${name}`
        : undefined,
      entrypoint: [DEDICATED_APP_NAME.POSTGRESQL].includes(
        // @ts-expect-error todo: fix type
        appType as DedicatedAppName,
      )
        ? `./entrypoints/pgo.sh delete ${DEDICATED_APP_NAME.POSTGRESQL} ${name}`
        : undefined,
    };

    const logo = dedicatedAppLogo[appType];

    const onUninstall = () => handleDedicatedAppUninstall(payload);

    if (isGridView) {
      return (
        <AppInstalledDedicatedItem
          key={appId}
          isUninstalling={isUninstalling}
          appName={name}
          appType={appType}
          status={status}
          appId={appId}
          appUrl={appUrl}
          onAppUninstall={onUninstall}
        />
      );
    }

    return (
      <Table.Row key={appId}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-0.5 text-[12px] text-white">
            <Render if={!logo}>
              <Icon icon={faRectangleTerminal} />
            </Render>
            <Render if={logo}>
              <img src={logo} alt="" />
            </Render>
          </div>
          <p className="truncate">{name}</p>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          <Job.Status status={status} />
        </Table.Cell>
        <Table.Cell />
        <Table.Cell className="flex-row justify-end gap-2">
          <Render if={appUrl}>
            <Button className="h-8 rounded-md px-2 py-1 text-footnote-large">
              <Link theme external blank to={appUrl!}>
                Open
              </Link>
            </Button>
          </Render>
          <Button
            disabled={!appId}
            className="h-8 rounded-md px-2 py-1 text-footnote-large"
          >
            <Link theme external blank to={path.app(appId)}>
              Details
            </Link>
          </Button>
          <Button
            loading={isUninstalling}
            variant="error"
            className="h-8 rounded-md px-2 py-1 text-footnote-large"
            onClick={onUninstall}
          >
            Uninstall
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  const makeBuiltInApp = ({ icon, name, path, tags }: App.BuiltInItem) => {
    if (isGridView) {
      return (
        <AppItem.BuiltIn
          key={path}
          icon={icon}
          name={name}
          path={path}
          tags={tags}
        />
      );
    }

    return (
      <Table.Row key={path}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-2 text-[12px] text-white">
            <Icon icon={icon} />
          </div>
          <Link theme className="truncate underline" to={path}>
            {name}
          </Link>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>Installed</Table.Cell>
        <Table.Cell>
          <Job.Tags tags={tags} />
        </Table.Cell>
        <Table.Cell className="flex-row items-center justify-end">
          <Link
            to={path}
            className="h-8 rounded-md px-4 py-1 text-footnote-large"
          >
            Open
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (isGridView) {
    return (
      <>
        <Helmet title={makeTitle('Installed Apps', '%p', '%c')} />
        <div className="grid grid-cols-3 gap-x-10 gap-y-8 hdp:grid-cols-2">
          {installedDedicatedApps
            .filter(filterdedicatedApp)
            .map(makeDedicatedApp)}
          <Render if={!appName}>{builtItApps.map(makeBuiltInApp)}</Render>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet title={makeTitle('Installed Apps', '%p', '%c')} />
      <Table className="w-full auto-rows-min grid-cols-[minmax(240px,auto)_repeat(3,max-content)_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>ID / Name</Table.Head>
            <Table.Head>Preset Name</Table.Head>
            <Table.Head>Status</Table.Head>
            <Table.Head>Tags</Table.Head>
            <Table.Head className="text-right">Actions</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {installedDedicatedApps
            .filter(filterdedicatedApp)
            .map(makeDedicatedApp)}
          <Render if={!appName}>{builtItApps.map(makeBuiltInApp)}</Render>
        </Table.Body>
        <Table.Body />
      </Table>
    </>
  );
};
