import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { App } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { getStorageAppsByAppType } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, invariant } from '@utils';

import { ChaseSpinner, Field } from '@components';
import { Mlflow } from '@components/Ui';

type Props = {
  required?: boolean;
  error?: string;
  onChange: (app: App.DedicatedModel) => void;
};

export const JobMlflowField: React.FC<Props> = ({
  required,
  error,
  onChange,
}) => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  const { storageUrl } = as.c(cluster);

  const [fetching, setFetching] = useState(false);
  const [activeAppName, setActiveAppName] =
    useState<App.DedicatedModel['name']>('');
  const [apps, setApps] = useState<App.DedicatedModel[]>([]);

  useEffect(() => {
    const getApps = async () => {
      try {
        setFetching(true);

        invariant(projectName);

        const apps = await getStorageAppsByAppType({
          organizationName,
          projectName,
          storageUrl,
          appType: DEDICATED_APP_NAME.MLFLOW,
        });

        setApps(apps.filter(({ status }) => status === App.Status.Running));
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setFetching(false);
      }
    };

    getApps();
  }, [storageUrl, organizationName, projectName]);

  const handleChange = (value: App.DedicatedModel['name']) => {
    const app = apps.find(({ name }) => name === value)!;

    setActiveAppName(value);
    onChange(app);
  };

  const makeApp = ({ name }: App.DedicatedModel) => {
    return (
      <Field.SelectItem
        key={name}
        value={name}
        className="z-50 w-full transition-colors hover:bg-background"
      >
        <p>{name}</p>
      </Field.SelectItem>
    );
  };

  const renderOptions = () => {
    if (fetching) {
      return (
        <div className="flex justify-center">
          <ChaseSpinner color="primary" />
        </div>
      );
    }

    if (apps.length === 0) {
      return <p className="px-10 text-center">No running apps</p>;
    }

    return apps.map(makeApp);
  };

  return (
    <Field.CustomSelect
      note="Connect MLFlow server"
      value={activeAppName}
      error={error}
      onChange={handleChange}
    >
      <div className="flex items-center gap-2" slot="value">
        <Mlflow />
        <div className="text-left">
          <p
            className={clsx('text-caption text-neural-04', {
              'required-label': required,
            })}
          >
            MLFlow Server
          </p>
          <p>{activeAppName}</p>
        </div>
      </div>
      <div className="flex items-center gap-2" slot="placeholder">
        <Mlflow />
        <p
          className={clsx('text-neural-04', {
            'required-label': required,
          })}
        >
          Choose a MLFlow Server
        </p>
      </div>
      <div className="min-w-[240px] py-2">{renderOptions()}</div>
    </Field.CustomSelect>
  );
};
