import { useCallback, useMemo } from 'react';

import { Hostname } from '@typings';
import { JWT_TOKEN, ORIGIN } from '@constants';
import { getOriginFromToken, localStorage } from '@utils';
import { origin as originContent } from '@content';

export const useHostname = () => {
  const origin = useMemo(() => {
    const token = localStorage.get<string | null>(JWT_TOKEN);
    const origin = getOriginFromToken(token);

    return origin;
  }, []);

  const checkHostname = useCallback(
    (host: string) =>
      window.location.origin.includes(host) || origin?.includes(host),
    [origin],
  );

  const title = useMemo(() => {
    if (originContent[ORIGIN as Hostname]) {
      return originContent[ORIGIN as Hostname];
    }

    if (checkHostname(Hostname.Scottdc)) {
      return originContent[Hostname.Scottdc];
    }

    if (checkHostname(Hostname.Novoserve)) {
      return originContent[Hostname.Novoserve];
    }

    if (checkHostname(Hostname.Imhpc)) {
      return originContent[Hostname.Imhpc];
    }

    if (checkHostname(Hostname.Megaport)) {
      return originContent[Hostname.Megaport];
    }

    if (checkHostname(Hostname.Wwt)) {
      return originContent[Hostname.Wwt];
    }

    if (checkHostname(Hostname.Geico)) {
      return originContent[Hostname.Geico];
    }

    if (checkHostname(Hostname.Onue)) {
      return originContent[Hostname.Onue];
    }

    return 'Apolo';
  }, [checkHostname]);

  return { title, checkHostname };
};
