import { useAuth0 } from '@auth0/auth0-react';

import { Button, Helmet } from '@components';

export const AuthPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleRegister = () =>
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
    });

  return (
    <>
      <Helmet
        title="Log In / Sign Up"
        description="Access your account securely and effortlessly by authenticating your identity"
      />
      <Button className="px-16 capitalize" onClick={() => loginWithRedirect()}>
        Log in
      </Button>
      <p className="-mt-2 text-neural-04">
        Don&apos;t have an account?
        <Button
          variant="ghost-primary"
          className="px-2"
          onClick={handleRegister}
        >
          Sign up
        </Button>
      </p>
    </>
  );
};
