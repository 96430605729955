import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { JWT_TOKEN } from '@constants';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { localStorage } from '@utils';

export const useInviteSignupVerification = () => {
  const dispatch = useDispatch();
  const { emailVerified, termsAccepted } = useSelector(userSelector);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const updateToken = async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'off' });

        localStorage.set<string>(JWT_TOKEN, token);

        return token;
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    if (emailVerified === false && termsAccepted) {
      updateToken();
    }
  }, [emailVerified, termsAccepted, getAccessTokenSilently, dispatch]);
};
