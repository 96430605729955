import { cn } from '@utils';

type Props = {
  className?: string;
};

export const GeicoLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[109px] w-[633px] text-white', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 633 109"
      fill="currentColor"
    >
      <path
        fill="#104293"
        fillRule="evenodd"
        d="M.7 39C.7 8.3 18.1.8 47 .8h48.5c36.6 0 41.6 13.4 41.6 33.8v3.1h-31.7c0-10.3-5.9-11.5-16.2-11.5H54.1c-16.2 0-20.2 3.7-20.2 21.8v13c0 18 4 21.8 20.2 21.8h34.5c8.4 0 16.8-1.2 16.8-11.2v-3.1H65.3V46.7h72.4v29.8c0 30.2-15.9 31.7-51.9 31.7H47C18.1 108.2.7 100.7.7 70V39ZM145.5 2h115v24.2h-82.7v17.1h78.3v21.8h-78.3v16.8h84.5V107H145.5V2Z"
        clipRule="evenodd"
      />
      <path fill="#104293" d="M304.3 2H272v105h32.3V2Z" />
      <path
        fill="#104293"
        fillRule="evenodd"
        d="M313.9 39C313.9 8.3 331.3.8 360.2.8h37.9c45.7 0 51.6 9.9 51.3 39.8h-32v-3.1c0-7.5-5.6-9.6-15.2-9.6H367c-16.8 0-19.9 8.1-19.9 21.1v11.2c0 15.2 3.1 21.1 18.3 21.1h34.2c14.6 0 19-1.5 19-14.6h32.3v9.9c0 17.7-6.5 31.7-38.8 31.7h-51.9c-28.9 0-46.3-7.5-46.3-38.2V39ZM456.5 39C456.5 8.3 473.6.8 502.8.8h51.6c28.9 0 46.3 7.5 46.3 38.2v31c0 30.8-17.4 38.2-46.3 38.2h-51.6c-29.2 0-46.3-7.5-46.3-38.2V39Zm33 21.1c0 15.2 3.1 21.1 18.3 21.1h41.3c15.2 0 18.3-5.9 18.3-21.1V48.9c0-13-3.1-21.1-20.2-21.1h-37.6c-17.1 0-20.2 8.1-20.2 21.1v11.2h.1ZM618.3 80c-8.1 0-14.3 6.2-14.3 14s6.2 14 14.3 14c7.8 0 14-6.2 14-14s-6.2-14-14-14Zm-.6 2.5h.6c6.2 0 11.2 5 11.2 11.5s-5 11.5-11.2 11.5c-6.5 0-11.5-5-11.5-11.5s4.7-11.5 10.9-11.5Zm-5 3.4v16.2h2.5v-7.2h2.8l4.3 7.2h2.8l-4.7-7.2c2.8-.3 4.4-1.9 4.4-4.7 0-2.8-2.2-4.3-5.9-4.3h-6.2Zm2.5 7.2v-5h3.4c2.5 0 3.4.6 3.4 2.5 0 1.5-.9 2.5-2.8 2.5h-4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
