/**
 * Local storage constants
 */
export const JWT_TOKEN = 'JWT_TOKEN';
export const PLATFORM_CONTEXT = 'PLATFORM_CONTEXT';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const VIEW_TYPE = 'VIEW_TYPE';

export const NO_ORGANIZATION = null;
export const NO_ORGANIZATION_NAME = 'NO_ORG';

export const DEFAULT_JOB_RUNTIME_MINUTES = 1440;

export const CAROUSEL_SETTINGS = {
  vertical: true,
  arrows: false,
  draggable: false,
  infinite: false,
};

export const PREFIX_TO_FACTOR = {
  '': 1,
  /**
   * Binary
   */
  Ki: 2 ** 10,
  Mi: 2 ** 20,
  Gi: 2 ** 30,
  Ti: 2 ** 40,
  Pi: 2 ** 50,
  /**
   * Decimal
   */
  k: 10 ** 3,
  m: 10 ** 6,
  M: 10 ** 6,
  G: 10 ** 9,
  T: 10 ** 12,
  P: 10 ** 15,
};

/**
 * Dedicated proper app names that handled manually
 */
export const DEDICATED_APP_NAME = {
  APOLO_DEPLOY: 'apolo-deploy',
  DIFY: 'dify',
  FOCUS: 'fooocus',
  JUPYTER_LAB: 'jupyter-lab',
  JUPYTER: 'jupyter',
  LLM_INFERENCE: 'llm-inference',
  MLFLOW: 'mlflow',
  POSTGRESQL: 'postgresql',
  PRIVATE_GPT: 'private-gpt',
  PYCHARM: 'pycharm',
  SHELL: 'shell',
  STABLE_DIFFUSION: 'stable-diffusion',
  TEXT_EMBEDDING_INFERENCE: 'text-embeddings-inference',
  VS_CODE: 'vscode',
  WEAVIATE: 'weaviate',
} as const;

export const SUPPORTED_GPU_MAKES = ['nvidia', 'intel', 'amd'] as const;

export const TELEMETRY = {
  STEP_X: 10,
  INTERVAL_Y: 5,
  /**
   * Chat column count
   */
  CHART_COUNT: 12,
};

export const BILLING_ALL_ENTRY = 'all';
