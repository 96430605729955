import { OrganizationInvite, Params, Payload, Project } from '@typings';
import { API_ADMIN } from '@constants';
import { axios } from '@features';
import { formatOrganizationInvite } from '@utils';

export const checkOrganizationExists = async (name: string) =>
  axios.get(`${API_ADMIN}/orgs/${name}`);

export const checkProjectExists = async ({
  organization,
  cluster,
  project,
}: Payload.CheckProjectExists) => {
  const url = organization
    ? `${API_ADMIN}/clusters/${cluster}/orgs/${organization}/projects/${project}`
    : `${API_ADMIN}/clusters/${cluster}/projects/${project}`;

  return axios.get(url);
};

export const createOrganization = async (name: string) =>
  axios.post<never, never, { name: string }>(`${API_ADMIN}/orgs`, { name });

export const createProject = async ({
  organization,
  cluster,
  payload,
}: Omit<Payload.CheckProjectExists, 'project'> & {
  payload: Payload.CreateProject;
}) => {
  const url = organization
    ? `${API_ADMIN}/clusters/${cluster}/orgs/${organization}/projects`
    : `${API_ADMIN}/clusters/${cluster}/projects`;

  return axios.post<never, Project, { name: string }>(url, payload);
};

export const inviteUserToOrganization = async ({
  email,
  role,
  organizationName,
}: Params.InviteUserToOrganization) => {
  try {
    const payload = {
      email,
      role_name: role,
      org_name: organizationName,
      origin: window.location.origin,
    };

    const invite = await axios.post<never, OrganizationInvite.Response>(
      `${API_ADMIN}/invites`,
      payload,
    );

    return formatOrganizationInvite(invite);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationSentInvites = async () => {
  try {
    const invites = await axios.get<never, OrganizationInvite.Response[]>(
      `${API_ADMIN}/invites`,
    );

    return invites.map(formatOrganizationInvite);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationsInvites = async () => {
  try {
    const invites = await axios.get<never, OrganizationInvite.Response[]>(
      `${API_ADMIN}/invites/incoming`,
    );

    return invites.map(formatOrganizationInvite);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeOrganizationInvite = ({
  inviteId,
}: Params.RemoveOrganizationInvite) => {
  return axios.delete(`${API_ADMIN}/invites/${inviteId}`);
};

export const acceptOrganizationInvite = ({
  inviteId,
}: Params.AcceptOrganizationInvite) => {
  return axios.post(`${API_ADMIN}/invites/${inviteId}/accept`);
};

export const declineOrganizationInvite = ({
  inviteId,
}: Params.DeclineOrganizationInvite) => {
  return axios.post(`${API_ADMIN}/invites/${inviteId}/decline`);
};
