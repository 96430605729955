import { useState } from 'react';
import dayjs from 'dayjs';
import { faEnvelope } from '@fortawesome/pro-thin-svg-icons';

import { OrganizationInvite } from '@typings';
import { toast } from '@features';
import { acceptOrganizationInvite } from '@services';
import { getConfig, getOrganizationsInvites } from '@thunks';
import { organizationsInvitesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { toastifyResponseError } from '@utils';

import { Button, Modal, Table } from '@components';
import { Layout } from '@components/Layouts';
import { OrganizationDeclineInviteModal } from '@components/Organization';
import { EmptyContent, OrganizationInviteStatus } from '@components/Ui';

export const OrganizationsInvitesPage = () => {
  const dispatch = useDispatch();
  const invites = useSelector(organizationsInvitesSelector);

  const [loadingId, setLoadingId] = useState('');

  const resetLoading = () => setLoadingId('');

  const handleInviteAccept = async (
    inviteId: string,
    organizationName: string,
  ) => {
    try {
      setLoadingId(inviteId);

      await acceptOrganizationInvite({ inviteId });
      await dispatch(getOrganizationsInvites());
      await dispatch(getConfig());

      toast.success(`You have been added to organization ${organizationName}`);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      resetLoading();
    }
  };

  const makeInvite = ({
    id: inviteId,
    role,
    expiredAt,
    status,
    organizationName,
  }: OrganizationInvite.Interface) => {
    const date = dayjs(expiredAt).format('L');
    const loading = inviteId === loadingId;

    return (
      <Table.Row key={inviteId}>
        <Table.Cell>{organizationName}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell>
          <OrganizationInviteStatus status={status} />
        </Table.Cell>
        <Table.Cell className="flex-row items-center justify-end gap-2">
          <Modal
            content={
              <OrganizationDeclineInviteModal
                inviteId={inviteId}
                organizationName={organizationName}
              />
            }
          >
            <Button
              variant="secondary"
              className="h-8 rounded-md px-3 py-1 text-body-medium font-400 text-error"
            >
              Decline
            </Button>
          </Modal>
          <Button
            loading={loading}
            variant="rebecca"
            className="h-8 rounded-md px-3 py-1 text-body-medium font-400"
            onClick={() => handleInviteAccept(inviteId, organizationName)}
          >
            Accept
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    if (!invites.length) {
      return (
        <EmptyContent
          className="mt-20"
          icon={faEnvelope}
          title="No pending invites"
          text="You currently don’t have any organizations invites. Please reach out to manager, who can invite you by providing your username or email"
        />
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(4,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Organization</Table.Head>
            <Table.Head>Role</Table.Head>
            <Table.Head>Expiry Date</Table.Head>
            <Table.Head>Status</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{invites.map(makeInvite)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout
      isProjectReffered={false}
      clusterContextTracker={false}
      projectContextTracker={false}
      organizationContextTracker={false}
      title="Organizations Invites"
    >
      <Layout.Content>{renderContent()}</Layout.Content>
    </Layout>
  );
};
