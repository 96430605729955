import { useState } from 'react';
import dayjs from 'dayjs';

import { OrganizationInvite } from '@typings';
import { toast } from '@features';
import { acceptOrganizationInvite } from '@services';
import { setContext } from '@slices';
import {
  getConfig,
  getOrganizationsInvites,
  getPermissions,
  getUserClusters,
} from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { as, toastifyResponseError } from '@utils';

import { Button } from '@components/Shared';

type Props = {
  invite: OrganizationInvite.Interface;
};

export const OnboardingOrganizationInviteSlide = ({ invite }: Props) => {
  const dispatch = useDispatch();
  const { username } = useSelector(userSelector);

  const [loading, setLoading] = useState(false);

  const {
    id: inviteId,
    organizationName,
    expiredAt,
    role,
  } = as.o<OrganizationInvite.Interface>(invite);

  const handleInviteAccept = async () => {
    try {
      setLoading(true);

      await acceptOrganizationInvite({ inviteId });
      const {
        orgs: [organization],
        clusters,
        adminUrl,
      } = await dispatch(getConfig());
      await dispatch(getUserClusters(adminUrl, username));
      await dispatch(getPermissions(username, 'blob:'));
      await dispatch(getOrganizationsInvites());

      if (!organization) {
        return;
      }

      const cluster =
        clusters.find(({ orgs }) => orgs.includes(organization.name)) ?? null;

      dispatch(setContext({ organization, cluster }));

      toast.success(`You have been added to organization ${organizationName}`);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="!flex h-[500px] flex-col justify-center gap-10">
      <h3 className="truncate text-h3">
        You have been invited to organization&nbsp;🎉
      </h3>
      <div className="flex flex-col gap-3">
        <p className="text-h6 font-500">
          <span className="space-after text-neural-04">Organization name:</span>
          {organizationName}
        </p>
        <p className="text-h6 font-500">
          <span className="space-after text-neural-04">Expiry date:</span>
          {dayjs(expiredAt).format('L')}
        </p>
        <p className="text-h6 font-500">
          <span className="space-after text-neural-04">Role:</span>
          <span className="capitalize">{role}</span>
        </p>
      </div>
      <div className="flex gap-3">
        <Button loading={loading} className="px-8" onClick={handleInviteAccept}>
          Accept and Go
        </Button>
      </div>
    </div>
  );
};
