import { useEffect } from 'react';

import { App, Job } from '@typings';
import { builtItApps, dedicatedApps } from '@content';

import { AppItem } from '@components/Ui';

type Props = {
  installedDedicatedApps: App.DedicatedModel[];
  setTitle: (title: string) => void;
};

export const AppsOutlet = ({ installedDedicatedApps, setTitle }: Props) => {
  useEffect(() => {
    setTitle('All Apps');
  }, [setTitle]);

  const makeApp = (app: Job.Item) => {
    const { name } = app;
    const installedDedicatedAppsCount = installedDedicatedApps.filter(
      ({ appType }) => appType === name,
    ).length;
    const installingJobCount = installedDedicatedApps.filter(
      ({ status }) => status === App.Status.Installing,
    ).length;

    return (
      <AppItem
        key={name}
        name={name}
        installingJobCount={installingJobCount}
        installedJobCount={installedDedicatedAppsCount}
        job={app}
      />
    );
  };

  const makeBuiltInApp = ({
    icon,
    name,
    path,
    tags,
    description,
  }: App.BuiltInItem) => (
    <AppItem.BuiltIn
      key={name}
      name={name}
      icon={icon}
      path={path}
      tags={tags}
      description={description}
    />
  );

  return (
    <div className="grid auto-rows-min grid-cols-3 gap-10 hdp:grid-cols-2">
      {dedicatedApps.map(makeApp)}
      {builtItApps.map(makeBuiltInApp)}
    </div>
  );
};
